// import React from "react";
// import "./printdata.css"
// import dateFormat from "dateformat";
// import Logo from "../../Images/Logo.svg";
// import { formatMoney } from "../../App";

// export default class PrintData extends React.Component {
//   renderTableChunk = (dataChunk, startIndex) => {
//     return (
//       <div className="printtabledata" key={startIndex}>
//         <img className="tom_logo_print" src={Logo} alt="image error" />
//         <div className="printDataTitle">COMPLETED BOOKINGS</div>
//         <table>
//           <thead>
//             <tr className="print_header">
//               <th className="print_header_text">S.No</th>
//               <th className="print_header_text">Member</th>
//               <th className="print_header_text">Nurse</th>
//               <th className="print_header_text">Duty Hours</th>
//               <th className="print_header_text">From</th>
//               <th className="print_header_text">To</th>
//               <th className="print_header_text">Status</th>
//               <th className="print_header_text">Fee</th>
//             </tr>

//           </thead>
//           <tbody>
//             {dataChunk.map((printdata, index) => (
//               <tr key={index + startIndex}>
//                 <td className="print_header_textss" style={{textAlign:"center"}}>{startIndex+index + 1}</td>
//                 <td className="print_header_textss">{printdata.customer}</td>
//                 <td className="print_header_textss">{printdata.nursename}</td>
//                 <td className="print_header_textss" style={{textAlign:"center"}}>{printdata.working_hours}</td>
//                 <td className="print_header_textss" style={{textAlign:"center"}}>{printdata.fromdate}</td>
//                 <td className="print_header_textss" style={{textAlign:"center"}}>{printdata.todate}</td>
//                 <td className="print_header_textss" style={{textAlign:"center"}}>{printdata.status}</td>
//                 <td className="print_header_textss" style={{textAlign: 'right'}}>{formatMoney(printdata.totalcost)}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <div className="page-break"></div>
//       </div>
//     );
//   };

//   render() {
//     const rowsPerPage = 30; // Adjust this number based on your page layout and font size
//     const { printTableData } = this.props;
//     const totalChunks = Math.ceil(printTableData.length / rowsPerPage);

//     const tableChunks = [];
//     for (let i = 0; i < totalChunks; i++) {
//       const startIndex = i * rowsPerPage;
//       const dataChunk = printTableData.slice(startIndex, startIndex + rowsPerPage);
//       tableChunks.push(this.renderTableChunk(dataChunk, startIndex));
//     }

//     return <div>{tableChunks}</div>;
//   }
// }




import React from 'react';
import "./printdata.css"
import Logo from "../../Images/Logo.png";
// import { timerDifference } from "../../App";
import { formatMoney } from "../../App";

class PrintData extends React.Component {

  renderTableChunk = (dataChunk, startIndex, totalChunks, rowsPerPage) => {
    const { printtabledata, HeadingButton, type } = this.props;
    const representativePrintData = HeadingButton && HeadingButton.length > 0 ? HeadingButton[0] : null;
    const totalPages = Math.ceil(totalChunks / rowsPerPage);
    const currentPage = Math.ceil(startIndex / rowsPerPage) + 1;

    return (
      <div className="printtabledata" key={startIndex}>
        <img className="tom_logo_print" src={Logo} alt="image error" />
        <div className="printDataTitle">Completed Bookings</div>
        <div className='kwd_vlaues_font'>All values in KWD</div>
        <table>
          <thead className="print_header">
            <tr>
              <th className="print_header_text" style={{ textAlign: "center" }}>S.No</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Member</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Nurse</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Duty Hours</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>From</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>To</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Status</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Cost</th>
            </tr>
          </thead>
          <tbody>
            {dataChunk.map((printdata, index) => (
              <tr key={startIndex + index}>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{startIndex + index + 1}</td>
                <td className="print_header_textss">{printdata.customer}</td>
                <td className="print_header_textss">{printdata.nursename}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.working_hours}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.fromdate}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.todate}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.status}</td>
                <td className="print_header_textss" style={{ textAlign: 'right' }}>{printdata.totalcost}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="print_footer">
          Page {currentPage} of {totalPages}
        </div>
        {dataChunk.length === rowsPerPage && startIndex + rowsPerPage < totalChunks && <div className="page-break"></div>}
      </div>
    );
  };

  render() {
    const rowsPerPage = 30; // Adjust this number based on your page layout and font size
    const { printTableData } = this.props;
    const totalChunks = Math.ceil(printTableData.length / rowsPerPage);

    const tableChunks = [];
    for (let i = 0; i < totalChunks; i++) {
      const startIndex = i * rowsPerPage;
      const dataChunk = printTableData.slice(startIndex, startIndex + rowsPerPage);
      tableChunks.push(this.renderTableChunk(dataChunk, startIndex, printTableData.length, rowsPerPage));
    }

    return <div>{tableChunks}</div>;
  }
}

export default PrintData;