import React from "react";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import Axios from "axios";
import { apiurl } from "../../App";
import Moment from "react-moment";
import print from "../../Images/print.svg";
// import pdf from "../../Images/pdf.svg";
import excel from "../../Images/excel.svg";
import { Select } from "antd";
import ReactSVG from "react-svg";
import IdlenurseTable from "./IdlenurseTable";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import pdf from "../../Images/pdf.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactToPrint from "react-to-print";
import PrintData from "./printdata";
import ReactExport from "react-data-export";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import dateformat from "dateformat";
import './IdlenurseTable.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from './Calendar';
import InsertInvitation from "@material-ui/icons/InsertInvitation";
import PDF from "../CommonPDF/PDF";
import downloadExcel from "../Common Excel/Excel";
import { Input, notification } from "antd";




const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
var moment = require("moment");
var dateFormat = require("dateformat");
var now = new Date();
var current_day = dateFormat(now, "yyyy-mm-dd");
const headRows = [
  { id: "sno", label: "S.No", typeDt: 'sno' },
  { id: "nursename", label: "Nurse", typeDt: 'str' },
  { id: "gender", label: "Gender ", typeDt: 'str' },
  { id: "age", label: "Age " },
  { id: "experience", label: "Experience " },
  { id: "Nationality", label: "Nationality", typeDt: 'str' },
  { id: "IdleDays", label: "IdleDays" },
];
class DashboardTable extends React.Component {
  state = {
    openview: false,
    tabledatas: [],
    search: null,
    props_loading: true,
    wk_Mn_Yr_Full_Data: [],
    table_Data_full:[],
    spinner: false,
    calander_view_data:[],
    startDate:new Date(),
    startOfMonth:"",
    endOfMonth:"",
    selected_nurse:""
  };
  createData = (parameter) => {
    var keys = Object.keys(parameter);
    var values = Object.values(parameter);
    var returnobj = {};
    for (var i = 0; i < keys.length; i++) {
      returnobj[keys[i]] = values[i];
    }
    return returnobj;
  };

   // data has empty this alert msg showed print pdf excel
   Notification = () => {
    notification.warning({
      message: "No data found",
      placement: "topRight",
    });
  };

   // data has empty this alert msg showed print pdf excel
   Notification = () => {
    notification.warning({
      message: "No data found",
      placement: "topRight",
    });
  };

  modelopen = (data,id) => {
    if (data === "view") {
      let calander_view_data =this.state.table_Data_full[id]
      this.setState({ openview: true,calander_view_data,selected_nurse: calander_view_data?.Nursename.toUpperCase() });
    } 
  };
  closemodal = () => {
    this.setState({ openview: false, editopen: false });
  };
  // SEARCH FUNCTION
  searchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
    this.setState({});
  };
  componentWillMount = () => {
    this.getmethod();

    this.intervalId = setInterval(() => {
      this.getmethod();
    }, 120000);
  };


  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.intervalId);
  }

  getmethod = (rangeday) => {
    const startOfMonth = moment(this.state.startDate).startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment(this.state.startDate).endOf('month').format('YYYY-MM-DD');
    this.setState({
      props_loading: true,startOfMonth,endOfMonth
    });
    Axios({
      method: "post",
      url: apiurl + "Nurse/getnurseidleweb",
      data: {
        nursevendorId: localStorage.nurse_vendor_id
          ? localStorage.nurse_vendor_id
          : 0,
       /* fromDate: this.state.start_date,
        toDate: this.state.end_date,*/
        fromDate:startOfMonth,
        toDate:endOfMonth,
        period: "Day",
      },
    }).then((response) => {
      var tabledatas = [];
      response.data.data &&
        response.data.data.map((val, index) => {
          tabledatas.push({
            nursename: val.Nursename,
            gender: val.gender,
            age: val.age,
            experience: val.experience,
            Nationality: val.nationalityName,
            IdleDays: val.IdleDays,
            id: index,
          });
        });
      this.setState({
        tabledatas: tabledatas,
        table_Data_full:response.data.data,
        props_loading: false,
      });
    });
  };

 
  // PDF FUNCTION
  generatepdf = () => {
    if (this.state.tabledatas.length === 0) {
      alert("Table data is empty");
    } else {
      // alert("ee")
      const doc = new jsPDF("a3");
      var bodydata = [];
      this.state.tabledatas.map((data, index) => {
        bodydata.push([
          index + 1,
          data.nursename,
          data.gender,
          data.age,
          data.experience,
          data.Nationality,
          data.IdleDays,
        ]);
      });
      doc.autoTable({
        beforePageContent: function (data) {
          doc.text("Idle Nurses", 15, 23); // 15,13 for css
        },
        margin: { top: 30 },
        showHead: "everyPage",
        theme: "grid",
        head: [
          [
            "S.No",
            "Nurse Name",
            "Gender",
            "Age",
            "Experience",
            "Nationality",
            "Idle Days",
          ],
        ],
        body: bodydata,
      });

      doc.save("IdleNurses.pdf");
    }
  };
  // PRINT FUNCTION
  generateprint = () => {
    this.setState({
      printComOpen: true,
    });
  };
  render() {
    const { Option } = Select;
    const { t } = this.props;
    const heading = ((localStorage.nurse_language=='ar') ?[
      { id: "", label: t("tbl_head_calendar_view") },
      { id: "IdleDays", label: t("tbl_head_idle_days") },
      { id: "Nationality", label: t("tbl_head_nationality") },
      { id: "experience", label: t("tbl_head_experience") },
      { id: "age", label: t("tbl_head_age") },
      { id: "gender", label: t("tbl_head_gender") },
      { id: "nursename", label: t("tbl_head_nurse") },
      { id: "", label: t("tbl_head_s_no")  },
    ]:[
      { id: "", label: t("tbl_head_s_no")  },
      { id: "nursename", label: t("tbl_head_nurse") },
      { id: "gender", label: t("tbl_head_gender") },
      { id: "age", label: t("tbl_head_age") },
      { id: "experience", label: t("tbl_head_experience") },
      { id: "Nationality", label: t("tbl_head_nationality") },
      { id: "IdleDays", label: t("tbl_head_idle_days") },
      { id: "", label: t("tbl_head_calendar_view") }
    ])
    // SEARCH FUNCTION
    const { Search } = Input;
    const Tabledatas = this.state.tabledatas.filter((data) => {
      if (this.state.search === null) return data;
      else if (
        (data.nursename !== null &&
          data.nursename
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.gender != null &&
          data.gender
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.age != null &&
          data.age.toString().includes(this.state.search.toString())) ||
        (data.experience != null &&
          data.experience.toString().includes(this.state.search.toString())) ||
        (data.Nationality != null &&
          data.Nationality.toLowerCase().includes(
            this.state.search.toString()
          )) ||
        (data.IdleDays != null &&
          data.IdleDays.toString().includes(this.state.search.toString()))
      ) {
        return data;
      }
    });
    // EXCEL FUNCTION
    var multiDataSetbody = [];
    this.state.tabledatas.map((xldata, index) => {
      if (index % 2 !== 0) {
        multiDataSetbody.push([
          { value: index + 1, style: { alignment: { horizontal: "center" } } },
          { value: xldata.nursename },
          { value: xldata.gender },
          { value: xldata.age },
          { value: xldata.experience },
          { value: xldata.Nationality },
          { value: xldata.IdleDays },
        ]);
      } else {
        multiDataSetbody.push([
          {
            value: index + 1,
            style: {
              alignment: { horizontal: "center" },
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.nursename,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.gender,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.age,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.experience,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.Nationality,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.IdleDays,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
        ]);
      }
    });
    const multiDataSet = [
      {
        columns: [
          {
            title: "S.No",
            width: { wpx: 35 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Nurse Name",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Gender",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Age",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Nationality",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Experience",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Idle Days",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
        ],
        data: multiDataSetbody,
      },
    ];
    return (
      <div>
        <div className="title_dashboard">
          <div className="title_header"> {t('idle_nurse')} </div>
          <div
            style={{ fontSize: "16px", display: "flex", alignItems: "center", direction: this.props.i18n.language == "ar" ? "rtl" : "" }}
          >
            <div className="rangePicker_Label">
                        {t('select_month')}
            </div>
              <DatePicker
                      selected={this.state.startDate}
                      onChange={(date) =>{this.setState({startDate:date},()=>{this.getmethod()})}}
                      dateFormat="MMM-yy"
                      showMonthYearPicker
                      showIcon
                      ref="month_calendar"
                    />
              <InsertInvitation onClick={(e)=>{this.refs.month_calendar.setOpen(true)}} style={{position:'relative',right:'40px',cursor:'pointer'}}></InsertInvitation>
            <DatePicker
              selected={this.state.startDate}
              onChange={(date) => { this.setState({ startDate: date }, () => { this.getmethod() }) }}
              dateFormat="MMM-yy"
              showMonthYearPicker
              showIcon
              ref="month_calendar"
            />
            <InsertInvitation onClick={(e) => { this.refs.month_calendar.setOpen(true) }} style={{ position: 'relative', right: this.props.i18n.language == "ar" ? "-30px" : '40px', cursor: 'pointer', zIndex: this.props.i18n.language == "ar" ? "999" : '' }}></InsertInvitation>
            <Search
              placeholder={t('search')}

              onSearch={(value) => console.log(value)}
              style={{ width: 150, textAlign: this.props.i18n.language == "ar" ? "right" : '', direction: this.props.i18n.language == "ar" ? "rtl" : "" }}
              onChange={(e) => this.searchChange(e)}
            />
            <div className="icon_head">
              {/* <ReactSVG
                src={pdf}
                onClick={this.generatepdf}
                style={{ marginRight:this.props.i18n.language == "ar" ? "15px" : "15px", marginLeft: this.props.i18n.language == "ar" ?"15px" : "15px" }}
              /> */}

              <PDF
                weekMonthYearData={this.state.tabledatas}
                headRows={headRows}
                filename={"Idlenurse Bookings"}
                headingTitle={'Idlenurse Bookings'}
                lang={this.props.i18n.language}
              />

              <img
                onClick={() => {
                  downloadExcel('Idle Nurses', this.state.tabledatas, headRows, '', [])
                }}
                src={excel}
                style={{ cursor: 'pointer', height: '24px' }}
              />
              {this.state.tabledatas.length === 0 ? (
                <ReactSVG src={print} onClick={this.Notification} />
              ) : (
                <ReactToPrint
                  trigger={() => <ReactSVG src={print} />}
                  content={() => this.componentRef}
                />
              )}
              <div style={{ display: "none" }}>
                <PrintData
                  printTableData={this.state.tabledatas}
                  ref={(el) => (this.componentRef = el)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="idel_nurse_table_div">
            <Tablecomponent
              heading={heading}
              //   tableicon_align={"cell_eye"}
              rowdata={Tabledatas.length === 0 ? [] : Tabledatas}
              modelopen={(e,id) => this.modelopen(e,id)}
              props_loading={this.state.props_loading}
              //actionclose="close"
              EditIcon="close"
              DeleteIcon="close"
              //VisibilityIcon="close"
              HistoryIcon="close"
              LocationOnIcon="close"
              t={t}
              i18n={this.props.i18n}
            />
        </div>
        
        <Modalcomp
          visible={this.state.openview}
          title={`${this.state.selected_nurse} - ${t('calendar')}`}
          closemodal={(e) => this.closemodal(e)}
          xswidth={"xs"}
          clrchange="textclr"
          t={t}
          i18n={this.props.i18n}
        >
           <Calendar {...this.state} t={t} i18n={this.props.i18n}></Calendar>
        </Modalcomp>
       
      </div>
    );
  }
}
export default DashboardTable;