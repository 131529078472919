import React, { Component } from "react";
import Logo from "../../Images/Logo.png";
import TextField from "@material-ui/core/TextField";
import "./ResetPassword.css";
import Grid from "@material-ui/core/Grid";
import Eye from "../../Images/eye.svg";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckIcon from "@material-ui/icons/Check";
import Nurse_Login from "../../Images/nurse_login.svg";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import SuccessMsg from "./Success";
import { NavLink } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import Axios from "axios";
import { apiurl } from "../../App";
import { notification } from "antd";
import { Spin } from "antd";

export default class DoctorLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
      confirmHidden: true,
      uudiId: "",
      isLoading: false,
      password: "",
      confirmPassword: "",
      email: "",
      userId: 0,

      // Error State
      passwordErr: false,
      confirmPasswordErr: false,
      passwordMatchErr: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        uudiId: this.props.match.params.uuid,
      },
      () => {
        this.validateUuid(this.state.uudiId);
      }
    );
  }

  resetPassword = () => {
    this.props.history.push("/forgot");
  };

  navigateLogin = () => {
    this.props.history.push("/");
  };

  validateUuid = () => {
    try {
      this.setState({ isLoading: true });
      const host = window.location.host;
      const pathName = window.location.pathname.split("/")[1];
      const dataObj = {
        uuid: this.state.uudiId,
        host,
        pathname: pathName,
      };

      Axios({
        method: "POST",
        url: apiurl + "admin/validate_uuid",
        data: dataObj,
      })
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.data.status === 1) {
            this.setState({
              email: response.data.data[0].email,
              userId: response.data.data[0].user_id,
            });
          } else {
            notification.error({
              message: response.data.msg,
            });
            this.resetPassword();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (err) {
      this.setState({ isLoading: false });
      console.log("error", err);
    }
  };

  checkValidation = () => {
    if (!this.state.password) {
      this.setState({ passwordErr: true });
    } else if (!this.state.confirmPassword) {
      this.setState({ confirmPasswordErr: true });
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({ passwordMatchErr: true });
    } else {
      this.submitPassword();
    }
  };

  submitPassword = () => {
    try {
      this.setState({ isLoading: true });
      const host = window.location.host;
      const pathName = window.location.pathname.split("/")[1];
      const dataObj = {
        email: this.state.email,
        uuid: this.state.uudiId,
        password: this.state.password,
        user_id: this.state.userId,
        host,
        pathname: pathName,
      };

      Axios({
        method: "POST",
        url: apiurl + "admin/check_nd_reset_password",
        data: dataObj,
      })
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.data.status === 1) {
            notification.success({
              message: response.data.msg,
            });
            this.navigateLogin();
          } else {
            notification.error({
              message: response.data.msg,
            });
            this.resetPassword();
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } catch (e) {
      console.log("Reset Password Submit Error: ", e);
    }
  };

  toggleshow = () => {
    if(this.state.hidden === false){
      this.setState({ hidden: true });
    }else{
      this.setState({ hidden: false });
    }
  };

  toggleShowConfirm = () => {
    if(this.state.confirmHidden === false){
      this.setState({ confirmHidden: true });
    }else{
      this.setState({ confirmHidden: false });
    }
  };

  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
      passwordErr: false,
      passwordMatchErr: false,
    });
  };

  onChangeConfirmPassword = (e) => {
    this.setState({
      confirmPassword: e.target.value,
      confirmPasswordErr: false,
      passwordMatchErr: false,
    });
  };

  loginpush = () => {
    this.props.history.push("/");
  };

  render() {
    const iconStyle = {
      fontSize: '25px', // Change the size
      color: '#707070', // Change the color
    };
    return (
      <Spin
        className="bank_details_spinner_align"
        spinning={this.state.isLoading}
      >
        <div className="pharmacy_login_container">
          <Grid container>
            <Grid item xs={12} md={7} className="pharmacy_image_grid">
              <div className="pharmacy_image_container">
                <div className="pharmacy_image_div">
                  <div className="pharmacy_image_login">
                    <div>
                      <img
                        src={Nurse_Login}
                        alt="1"
                        className="pharmacy_image"
                      />
                      <div className="nurse_cmpny">Nurse Company</div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={5} className="pharmacy_grid_container">
              <div className="pharmacy_main_container">
                <div className="pharmacy_paper_div">
                  <div className="pharmacy_text_container">
                    <div className="logo_container">
                      <div className="logo_div">
                        <img className="logo_image" src={Logo} />
                      </div>
                    </div>
                    <div className="pharmacy_Welcometext-container">
                      <p className="Welcometext">RESET PASSWORD</p>
                    </div>
                    <div className="pharmacy_email_container">
                      <TextField
                        type={this.state.hidden ? "password" : "text"}
                        onChange={this.onChangePassword}
                        value={this.state.password}
                        label="New Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton onClick={this.toggleshow} style={{ left: "-4px" }}>
                                      {this.state.hidden ? <VisibilityOff style={iconStyle} /> : <Visibility style={iconStyle} />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <div
                        style={{
                          display: this.state.passwordErr ? "block" : "none",
                          color: "red",
                          textAlign: "left",
                          marginTop: "5px",
                        }}
                      >
                        Please Enter Password
                      </div>
                    </div>

                    <div className="confirm_container">
                      <TextField
                        type={this.state.confirmHidden ? "password" : "text"}
                        placeholder=""
                        className="trrainer_password"
                        label="Confirm Password"
                        onChange={this.onChangeConfirmPassword}
                        value={this.state.confirmPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                               <IconButton onClick={this.toggleShowConfirm} style={{ left: "-4px" }}>
                                      {this.state.confirmHidden ? <VisibilityOff style={iconStyle} /> : <Visibility style={iconStyle} />}
                                </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />

                      <div
                        style={{
                          display: this.state.confirmPasswordErr
                            ? "block"
                            : "none",
                          color: "red",
                          textAlign: "left",
                          marginTop: "5px",
                        }}
                      >
                        Please Enter Confirm Password
                      </div>

                      <div
                        style={{
                          display: this.state.passwordMatchErr
                            ? "block"
                            : "none",
                          color: "red",
                          textAlign: "left",
                          marginTop: "5px",
                        }}
                      >
                        Password and confirm password dosn't match
                      </div>
                    </div>
                    <div className="login_button_container">
                      <button
                        className="login"
                        component={NavLink}
                        to="/ResetPassword"
                        onClick={() => this.checkValidation()}
                      >
                        Submit
                      </button>
                    </div>
                    <div className="cancel_container">
                      <span className="cancelbutton" onClick={this.loginpush}>
                        Cancel
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Spin>
    );
  }
}
