import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import "./Nurse_view.css";
import CloseIcon from "@material-ui/icons/Close";
import dateformat from "dateformat";
import No_image_available from "../../Images/No_image_available.svg";
import Popover from "@material-ui/core/Popover";
import Backdrop from "@material-ui/core/Backdrop";
import moment from "moment";
import { formatMoney } from "../../App";
import MaleImg from "../../assests/images/MaleJpg.jpg"
import FemaleImg from "../../assests/images/FeMaleJpg.jpg"
export default class NurseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancel: null,
      view: false,
      ViewData: "",
      Cost: "",
      Duties: "",
      nursenames: "",
      nurse_anchorEl: "",
      address_anchorEl: null,
    };
  }

  getDutiesofnurse = (data) => {
    if (data == undefined) {
      var valuesArray = [];
    } else if (typeof data === 'object') {
      var valuesArray = data;
    } else {
      var valuesArray = JSON.parse(data);
    }
    let res = "";
    valuesArray && valuesArray.map((obj, i) => {
      res = res + obj["duties"].trim() + (i == valuesArray.length - 1 ? "" : ", ");
    })
    // this.setState({nursenames : res})
    return res

  }

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };
  open = () => {
    this.setState({ view: true });
  };
  onclose = () => {
    this.setState({ view: false });
  };

  handleAddressViewMore = (event) => {
    debugger
    this.setState({ address_anchorEl: event ? event.currentTarget : null });
  };
  handlenursenameViewMore = (event) => {
    this.setState({ nurse_anchorEl: event ? event.currentTarget : null });
  };

  render() {
    const { classes, onClose, cancel, selectedValue, t, ...other } = this.props;
    var ViewData = this.props.ViewData;
    // const nurse_address = this.props.is_from_manage_nuser ? ViewData.NurseAddress || '' : ViewData.address || ViewData.NurseAddress || ''
    const nurse_address = this.props.is_from_manage_nuser 
    ? ViewData.NurseAddress || '' 
    : ViewData.address || ViewData.NurseAddress || '';


    const open_address = Boolean(this.state.address_anchorEl);
    const addressid = open_address ? "simple-popover" : undefined;

    const nursename = Boolean(this.state.nurse_anchorEl);
    const nurseid = nursename ? "simple-popover" : undefined;

    return (
      <div className="doctor_popup_details">
        <Dialog
          className={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} nurse_profile_modal nurse_view_common_popup ${open_address ? " _zIndex" : ""}`}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          {...other}
          maxWidth={"md"}>

          <div>
            <CloseIcon className="modalcomp_closeicon" onClick={this.props.onClose} />
            <img 
            src={
              ViewData && this.props.is_from_manage_nuser ? ViewData.patientImage ? ViewData.patientImage :  ViewData.patientgender === 'Male'
              ? MaleImg
              : FemaleImg :

               (ViewData.profile_name ? ViewData.patientImage :  ViewData.patientgender === 'Male'
                ? MaleImg
                : FemaleImg)
            
            }
              alt="This IMG format is not supporting"
              style={{ height: "10" }} className="patient" />
          </div>

          <div>
            <Grid container className="nurse_view_grid_container" style={{ direction: this.props.i18n.language == "ar" ? "rtl" : "" }}>
              <>
                <Grid item xs={12} md={12} justify="center" style={{ marginTop: '10rem' }}>
                  <div className="common_nurse_view_name">
                    {ViewData && this.props.is_from_manage_nuser ? ViewData.PatientName : ViewData.Nursename || ViewData.PatientName}
                  </div>
                  <div className="common_nurse_view_age">
                    {ViewData && this.props.is_from_manage_nuser && ViewData.patientage != 0 ? ViewData.patientage : ViewData.patientage != 0 ? ViewData.patientage : ViewData.age || ViewData.nurseage || ""}
                    <span style={{ marginLeft: "4px" }}>{ViewData && this.props.is_from_manage_nuser && ViewData.patientage != 0 ? "yrs," : ViewData.patientage != 0 ? "yrs," : ""}</span> &nbsp;
                    <span className="Thingsof">{ViewData && this.props.is_from_manage_nuser ? ViewData.patientgender : ViewData.gender || ViewData.nurseGender}</span>
                  </div>
                  <div className="booking_head_div">
                    {t('booking_details')}
                  </div>
                </Grid>

                <Grid container style={{ margin: '3rem' }}>
                  <Grid item xs={6} md={6} >
                    <Grid container className={`${this.props.i18n.language != "ar" ? "Total_content_align" : ""} `}
                      style={{ direction: this.props.i18n.language == "ar" ? "ltr" : "", marginLeft: "8px" }}>
                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_2 nurse_postion " : ""} mb-3 `}
                        style={{ color: "black", fontWeight: "400", fontSize: "16px" }}>{t("tbl_head_booked_on")}
                      </Grid>
                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_1" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_0 " : ""} mb-3`} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div">
                          {ViewData && ViewData.book_date ? dateformat(ViewData.book_date, "dd-mmm-yy")
                            : dateformat(ViewData.Bookeddate, "dd-mmm-yy")}
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_5 nurse_postion " : ""} mb-3 `} style={{ color: "black", fontWeight: "400", fontSize: "16px" }}>
                        {t("tbl_head_from")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_4" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_3 pr-3" : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div">
                          {ViewData && dateformat(ViewData.from_date, "dd-mmm-yy")}
                        </span>
                      </Grid>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_8 nurse_postion" : ""} mb-3`} style={{ color: "black", fontWeight: "400", fontSize: "16px" }}>
                        {t("tbl_head_to")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_7" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_6 pr-3" : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div">
                          {ViewData && dateformat(ViewData.to_date, "dd-mmm-yy")}
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_11 nurse_postion" : ""} mb-3 `} style={{ color: "black", fontWeight: "400", fontSize: "16px" }}>
                        {t("tbl_head_duty_hours")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_10" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_9 pr-3" : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div">
                          {ViewData && ViewData.working_hours ? ViewData.working_hours : ViewData.duty_hours}
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_14 nurse_postion" : ""} mb-3`} style={{ color: "black", fontWeight: "400", fontSize: "16px" }}>
                        {t("start")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_13" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_12 pr-3" : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div">
                          <span style={{ textTransform: 'lowercase' }}>{ViewData && ViewData.from_time ? moment(ViewData.from_time, 'HH:mm:ss').format("hh:mm A") : ""}</span>
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_17 nurse_postion" : ""} mb-3`} style={{ color: "black", fontWeight: "400", fontSize: "16px" }}>
                        {t("end")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_16" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_15 pr-3" : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div">
                          <span style={{ textTransform: 'lowercase' }}>{ViewData && ViewData.to_time ? moment(ViewData.to_time, 'HH:mm:ss').format("hh:mm A") : ""}</span>
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_20 nurse_postion" : ""} mb-3`} style={{ color: "black", fontWeight: "400", fontSize: "16px" }}>
                        {t("designed_duties")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_19" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_18 pr-3" : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div" style={{ width: '100%', display: 'flex', direction: this.props.i18n.language == "ar" ? "rtl" : "", justifyContent: this.props.i18n.language == "ar" ? "end" : "" }}>

                          {ViewData && <span className={this.getDutiesofnurse(ViewData.Dutiesofnurse).length > 10 || this.getDutiesofnurse(ViewData.Dutiesofnurse).split(",").length > 1 ? "text_ellipsis_div" : "text_view_more"} > {this.getDutiesofnurse(ViewData.Dutiesofnurse)}  </span>}
                          {this.getDutiesofnurse(ViewData.Dutiesofnurse) != "" && (this.getDutiesofnurse(ViewData.Dutiesofnurse).length > 10 || this.getDutiesofnurse(ViewData.Dutiesofnurse).split(",").length > 1) &&
                            <span
                              className="instruction-view-more"
                              style={{ color: "var(--app-theme)" }}
                              onClick={(event) =>
                                this.handlenursenameViewMore(event)
                              }
                              aria-describedby={nurseid}
                            >
                              {t("view_more")}
                            </span>}
                        </span>

                        <div>
                          <Backdrop
                            open={nursename} sx={{ color: "#fff", }} className="view_backdrop">
                            <Popover
                              id={nurseid}
                              open={nursename}
                              anchorEl={this.state.nurse_anchorEl}
                              className="remain-instruction-popover"
                              onClose={() =>
                                this.handlenursenameViewMore(null)
                              }
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}

                            >
                              <div className="remain-instruction-div">
                                <div className="header-div" style={{ direction: localStorage.nurse_language == 'ar' ? "rtl" : "" }}>
                                  <p className="title" style={{ marginRight: localStorage.nurse_language == 'ar' ? "11px" : "", marginLeft: localStorage.nurse_language == 'ar' ? "0px" : "" }}>{t("designed_duties")}</p>
                                  <CloseIcon
                                    style={{ marginRight: localStorage.nurse_language == 'ar' ? "0px" : "", marginLeft: localStorage.nurse_language == 'ar' ? "5px" : "" }}
                                    className="vm_closeicon_title view_more_closeicon_title"
                                    onClick={() =>
                                      this.handlenursenameViewMore(null)
                                    }
                                  />
                                </div>
                                <div className="body-div" style={{ textAlign: localStorage.nurse_language == 'ar' ? "right" : "" }}>

                                  <span className="items">
                                    {this.getDutiesofnurse(ViewData.Dutiesofnurse)}
                                  </span>

                                </div>
                              </div>
                            </Popover>
                          </Backdrop>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={6} >
                    <Grid container style={{ direction: this.props.i18n.language == "ar" ? "ltr" : "", marginLeft: "12px" }}>
                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_2 nurse_postion" : ""} mb-3 common_nurse_view_label`}>
                        {t("address")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_1 " : ""}mb-3`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_0 " : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div " style={{ width: '100%', display: 'flex', direction: this.props.i18n.language == "ar" ? "rtl" : "", justifyContent: this.props.i18n.language == "ar" ? "end" : "" }}>

                          {ViewData && <span className={nurse_address.length > 10 ? "text_ellipsis_div" : "text_view_more"} > {nurse_address}  </span>}
                          {nurse_address != "" && nurse_address.length > 10 &&
                            <span
                              className={` instruction-view-more ${open_address ? " _zIndex" : ""}`}
                              style={{ color: "var(--app-theme)" }}
                              onClick={(event) =>
                                this.handleAddressViewMore(event)
                              }
                              aria-describedby={addressid}
                            >
                              {t("view_more")}
                            </span>}

                          <div>
                            <Backdrop
                              open={open_address} sx={{ color: "#fff", }} className="view_backdrop">
                              <Popover
                                id={addressid}
                                open={open_address}
                                anchorEl={this.state.address_anchorEl}
                                className="remain-instruction-popover"
                                onClose={() =>
                                  this.handleAddressViewMore(null)
                                }
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}

                              >
                                <div className="remain-instruction-div">
                                  <div className="header-div" style={{ direction: localStorage.nurse_language == 'ar' ? "rtl" : "" }}>
                                    <p className="title" style={{ marginRight: localStorage.nurse_language == 'ar' ? "11px" : "", marginLeft: localStorage.nurse_language == 'ar' ? "0px" : "" }}>{t('address')}</p>
                                    <CloseIcon
                                      style={{ marginRight: localStorage.nurse_language == 'ar' ? "0px" : "", marginLeft: localStorage.nurse_language == 'ar' ? "5px" : "" }}
                                      className="vm_closeicon_title view_more_closeicon_title"
                                      onClick={() =>
                                        this.handleAddressViewMore(null)
                                      }
                                    />
                                  </div>
                                  <div className="body-div" style={{ textAlign: localStorage.nurse_language == 'ar' ? "right" : "" }}>

                                    <span className="items">
                                      {nurse_address}
                                    </span>

                                  </div>
                                </div>
                              </Popover>
                            </Backdrop>
                          </div>
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_5 nurse_postion" : ""} mb-3 common_nurse_view_label`}>
                        {t("completed_days")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_4" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_3 " : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div">
                          {ViewData && ViewData.CompletedDays}
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_8 nurse_postion" : ""} mb-3 common_nurse_view_label`}>
                        {t("remaining_days")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_7" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_6 " : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        <span className="common_nurse_view_div">
                          {ViewData && ViewData.RemainingDays}
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6}
                        className={`${this.props.i18n.language == 'ar' ? "order_11 nurse_postion" : ""} mb-3 common_nurse_view_label`}>
                        {t("fee_with_kwd_per_month")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_10" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_9 " : ""} mb-3 `} style={{ maxWidth: "48%", direction: localStorage.nurse_language == 'ar' ? "rtl" : ""  }}>
                        <span className="common_nurse_view_div">
                          {ViewData && formatMoney(ViewData.amount)}  <span style={{ fontSize: "10px", direction : localStorage.nurse_language == 'ar' ? "rtl" : "" }}>{localStorage.nurse_language == 'ar' ? 'د.ك' : 'KWD'}</span>
                        </span>
                      </Grid>


                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_14 nurse_postion" : ""} mb-3 common_nurse_view_label`}>
                        {t("deal_with_kwd")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_13" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_12 " : ""} mb-3 `} style={{ maxWidth: "48%" }}>
                        {/* <span className="common_nurse_view_div">
                          {ViewData && ViewData.dealAmt ? (ViewData.dealOption == "%" ? (ViewData.dealAmt * ViewData.amount) / 100 : ViewData.dealAmt) : "-"}
                          {ViewData && ViewData.dealAmt ? <span style={{ fontSize: "10px" }}>KWD</span> : ""}
                        </span> */}

                        <span className="common_nurse_view_div">
                          {ViewData && ViewData.dealAmt ? (
                            ViewData.dealOption === "%" ? (
                              <>
                                {/* {(ViewData.dealAmt * ViewData.amount) / 100} */}
                                {ViewData.dealAmt}
                                <span style={{ fontSize: "13px" }}> %</span>
                              </>
                            ) : (
                              <>
                                {ViewData.dealAmt}
                                <span style={{ fontSize: "10px" }}> {localStorage.getItem("nurse_language") === "en" ? "KWD" : "د.ك"}</span>
                              </>
                            )
                          ) : (
                            "-"
                          )}
                        </span>
                      </Grid>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_17 nurse_postion" : ""} mb-3 common_nurse_view_label`}>
                        {t("billing_amount_with_kwd")}
                      </Grid>

                      <span style={{ fontWeight: "800" }} className={`${this.props.i18n.language == "ar" ? "order_16" : ""}`}>:</span>

                      <Grid item md={6} sm={6} className={`${this.props.i18n.language == 'ar' ? "order_15 " : ""} mb-3 `} style={{ maxWidth: "48%", direction: localStorage.nurse_language == 'ar' ? "rtl" : "" }}>
                        <span className="common_nurse_view_div">
                          {ViewData && formatMoney(ViewData.total_charge)} <span style={{ fontSize: "10px" }}>{localStorage.nurse_language == 'ar' ? 'د.ك' : 'KWD'}</span>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </div>
        </Dialog>
      </div>
    );
  }
}
