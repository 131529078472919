import * as ExcelJS from "exceljs";
import moment from "moment";
import logo from "../../assests/images/bannerone.png";
import tomimg from "../../assests/images/tomImagewhite.png";
import { Input, notification } from "antd";

const downloadExcel = (title, data, titleArray, imgBase64, exportdataTotal) => {
    debugger
    if (!data || data.length === 0 || !titleArray || titleArray.length === 0) {
        notification.warning({
            message: "No data found",
            placement: "topRight",
        });
        return;
    }

    var arraywidth = [];
    var propsHeader = title
    var widthAdjustDt = ''
    var sm_table_align = 0

    const formatMoney = (number) => {
        let num = Number(number);
        const options = {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3
        };

        var formattedNumber = num.toLocaleString('en-US', options); // Change 'en-US' to 'en-IN'
        const parts = formattedNumber.split('.');
        if (formattedNumber == 'NaN') {
            formattedNumber = "0";
        }
        if (parts.length === 1) {
            return `${formattedNumber}.000`;
        } else {
            const decimalPart = parts[1].padEnd(2, '0');
            // const integerPart = parts[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
            return `${parts[0]}.${decimalPart}`;
        }
    }

    switch (true) {
        case (window.location.href.includes("revenue")  && !(window.location.href.includes("totalrec"))): 
            arraywidth = [10, 15, 25, 20, 15, 15, 15];
            break;
        case window.location.href.includes("totalrec"):
            arraywidth = [10, 20, 20, 20, 15];
            break;
        case window.location.href.includes("nursehired"):
            arraywidth = [10, 20, 20, 15, 15, 15, 15, 15, 15, 15];
            break;
        case window.location.href.includes("completedbooking"):
            arraywidth = [10, 20, 20, 15, 15, 15, 15, 15, 15];
            break;
        case window.location.href.includes("ongoingbooking"):
            arraywidth = [10, 20, 20, 15, 15, 15, 15, 15, 15];
            break;
        case window.location.href.includes("bookingyettostart"):
            arraywidth = [10, 20, 20, 15, 15, 15, 15, 15, 15];
            break;
        case window.location.href.includes("idlenurse"):
            arraywidth = [10, 20, 20, 15, 15, 15, 15, 15, 15];
            break;
            case window.location.href.includes("managenurse"):
                arraywidth = [10, 20, 20, 15, 15, 15, 15, 15, 15];
                break;
            case window.location.href.includes("customerhistory"):
                if(titleArray?.length>6){
                    arraywidth = [10, 20, 15, 15, 15, 15, 15,];
                }else{
                arraywidth = [10, 20, 15, 15, 15, 25];
            }
            break;
        default:
            // Default case, arraywidth remains unchanged
            break;
    }


    fetch(logo)
        .then((response) => response.blob())
        .then((blob) => {
            try{
                var cellarray = [];
                var finalCellarray = [];
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result.split(",")[1];
                    const base64String2 = tomimg.split(",")[1];
                    const Base64Image = `data:image/png;base64,${base64String}`;
                    const Base64Image2 = `data:image/png;base64,${base64String2}`;
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet("Sheet1", {
                        views: [{ showGridLines: false }],
                    });
                    titleArray.forEach((title, index) => {
                        const cell = worksheet.getCell(
                            `${String.fromCharCode(66 + index)}8`
                        );
                        if (index == titleArray.length - 1) {
                            finalCellarray.push({ cell: cell });
                        }
                        if (title.label.includes("Tom")) {
                            const arrayPart = title.label.split("Tom");
                            cellarray.push({ cell: cell, text: arrayPart[1] });
                            // cell.value =
                            // // title.label
                            // {
                            //   richText:[
                            //     { text: ' ',
                            //     image: Base64Image, // Pass the base64 image directly
                            //     style: { width: 10, height: 10 },
                            //     font: { size: 12, color: { argb: 'FFFFFFFF' } },
                            //     },
                            //     {text:arrayPart.length==2 && ' ('+arrayPart[1],font:{size:5,color: { argb: 'FFFFFFFF' }}}
                            //   ]
                            // }
                        } else if (title.label.includes("(KWD)")) {
                            const arrayPart = title.label.split("(");
    
                            cell.value = {
                                richText: [
                                    {
                                        text: arrayPart[0],
                                        font: { size: 12, color: { argb: "FFFFFFFF" } },
                                    },
                                    {
                                        text: " (" + arrayPart[1],
                                        font: { size: 5, color: { argb: "FFFFFFFF" } },
                                    },
                                ],
                            };
                        } else {
                            cell.value = title.label;
                        }
                        cell.style = {
                            border: {
                                top: { style: "thin", color: { argb: "#ff010000" } },
                                right: { style: "thin", color: { argb: "ff010000" } },
                                bottom: { style: "thin", color: { argb: "ff010000" } },
                                left: { style: "thin", color: { argb: "ff010000" } },
                            }, // No borders
                        };
                        // cell.value = title.label;
                        const titleLength = arraywidth;
                        worksheet.getColumn(index + 2).width = Math.max(
                            titleLength[index],
                            10
                        );
                        cell.alignment = { horizontal: "center", vertical: "middle" };
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: { argb: "510f30" },
                        };
                        cell.font = {
                            color: { argb: "FFFFFFFF" },
                        };
                    });
                    {
                        exportdataTotal.length > 0 &&
                            titleArray.forEach((title, colIndex) => {
                                exportdataTotal &&
                                    colIndex + 1 == 1 &&
                                    exportdataTotal.map((headerd, rowind) => {
                                        // if(rowind==0){
    
                                        const cell = worksheet.getCell(
                                            `${String.fromCharCode(66 + rowind)}${colIndex + 9}`
                                        );
                                        cell.style = {
                                            border: {
                                                top: { style: "thin", color: { argb: "#ff010000" } },
                                                right: { style: "thin", color: { argb: "ff010000" } },
                                                bottom: { style: "thin", color: { argb: "ff010000" } },
                                                left: { style: "thin", color: { argb: "ff010000" } },
                                            }, // No borders
                                        };
                                        cell.value =
                                            headerd.income == "Total" || headerd.income == ""
                                                ? headerd.income
                                                : headerd.income;
                                        cell.alignment = {
                                            horizontal: headerd.income == "Total" ? 'center' : "right",
                                            vertical: "middle",
                                        };
                                        cell.font = {
                                            bold: true,
                                            size: 10,
                                            color: { argb: "510F30" },
                                        };
                                        cell.fill = {
                                            type: "pattern",
                                            pattern: "solid",
                                            fgColor: { argb: headerd.income == "" ? "" : "f8e0d8" },
                                        };
                                        // }
                                    });
                            });
                    }
                    data.forEach((row, rowIndex) => {
                        console.log(row);
                        titleArray.forEach((title, colIndex) => {
                            console.log(title);
                            const cell = worksheet.getCell(
                                `${String.fromCharCode(66 + colIndex)}${rowIndex + (exportdataTotal.length > 0 ? 10 : 9)
                                }`
                            );
                            cell.style = {
                                border: {
                                    top: { style: "thin", color: { argb: "#ff010000" } },
                                    right: { style: "thin", color: { argb: "ff010000" } },
                                    bottom: { style: "thin", color: { argb: "ff010000" } },
                                    left: { style: "thin", color: { argb: "ff010000" } },
                                }, // No borders
                            };
                            cell.value =
                                title.typeDt === "sno"
                                    ? rowIndex + 1
                                    : title.typeDt === "str"
                                        ? row[title.id]
                                        : title.typeDt === "date"
                                            ? moment(row[title.id]).format("DD-MMM-YY")
                                            : title.typeDt === "float" || title.typeDt === "amt"
                                                ? row[title.id] == null || row[title.id] == undefined
                                                    ? "-"
                                                    : row[title.id] == "0.000"
                                                        ? "-"
                                                        : row[title.id]
                                                : title.typeDt === "int"
                                                    ? row[title.id] == null || row[title.id] == undefined || row[title.id] === "undefined"
                                                        ? "-"
                                                        : row[title.id]
                                                    : row[title.id] == null || row[title.id] == undefined || row[title.id] === "undefined"
                                                        ? '-'
                                                        : row[title.id];
    
                            cell.alignment = {
                                horizontal:
                                    title.typeDt === "str"
                                        ? "left"
                                        : title.typeDt === "float" || title.typeDt === "amt"
                                            ? "right"
                                            : "center",
                                vertical: "middle",
                            };
                        });
                    });
                    const imgg = workbook.addImage({
                        base64: Base64Image2,
                        extension: "png",
                    });
    
                    finalCellarray.forEach((cellAddress) => {
                        const match = cellAddress.cell?._address.match(/([A-Z]+)(\d+)/);
                        if (match) {
                            const columnName = excelColumnToNumber(match[1]);
                            const rowNumber = parseInt(match[2], 10);
                            const cell = worksheet.getCell(rowNumber - 1, columnName);
                            cell.value = {
                                richText: [
                                    {
                                        text: "All Values in KWD",
                                        font: { bold: true, size: 10 ,color: { argb: "510f30" }},
                                        //  alignment: { vertical: "middle", horizontal: "end" },
                                    },
                                ],
                            };
    
                            cell.style = {
                                alignment: { relativeIndent: 1, horizontal: "right" },
                            };
                        }
                    });
    
                    cellarray.forEach((cellAddress) => {
                        const match = cellAddress.cell?._address.match(/([A-Z]+)(\d+)/);
                        if (match) {
                            const columnName = excelColumnToNumber(match[1]);
                            const rowNumber = parseInt(match[2], 10);
                            const leftMargin = 0.1;
                            worksheet.addImage(imgg, {
                                // base64: Base64Image2,
                                // extension: 'png',
    
                                tl: { col: columnName - 1 / 22, row: rowNumber - 1 / 1.5 },
                                ext: { width: 20, height: 8 },
                            });
                            worksheet.getCell(rowNumber, columnName).value = {
                                richText: [
                                    {
                                        text: "  " + cellAddress?.text,
                                        // font: { bold: true, size: 12 },
                                        alignment: { vertical: "middle", horizontal: "end" },
                                    },
                                ],
                            };
                        }
                    });
    
                    const img = workbook.addImage({
                        base64: Base64Image,
                        extension: "png",
                        tl: { col: 0, row: 0 },
                        br: { col: 10, row: 10 },
                    });
                    worksheet.addImage(img, {
                        tl: { col: 1, row: 1 },
                        ext: { width: 100, height: 70 },
                    });
    
                    var startColumn = "D6";
                    var endColumn = "I6";
    
                    switch (titleArray.length) {
                        case 12:
                            startColumn = "H6";
                            endColumn = "O6";
                            break;
                        case 11:
                            startColumn = "G6";
                            endColumn = "N6";
                            break;
                        case 10:
                            startColumn = "E6";
                            endColumn = "M6";
                            break;
                        case 9:
                            startColumn = "F6";
                            endColumn = "L6";
                            break;
                        case 8:
                            startColumn = "E6";
                            endColumn = "L6";
                            break;
                        case 7:
                            startColumn = "E6";
                            endColumn = "L6";
                            break;
                        case 6:
                            startColumn = "D6";
                            endColumn = "L6";
                            break;
                        case 5:
                        case 4:
                        case 3:
                        case 2:
                        case 1:
                            startColumn = "D6";
                            endColumn = "K6";
                            break;
                    }
    
                    console.log("startColumn:", startColumn);
                    console.log("endColumn:", endColumn);
    
                    const mergecellStart = startColumn.match(/([A-Z]+)(\d+)/);
                    const mergecellEnd = endColumn.match(/([A-Z]+)(\d+)/);
                    if (mergecellStart && mergecellEnd) {
                        const columnStart = excelColumnToNumber(mergecellStart[1]);
                        const rowStart = parseInt(mergecellStart[2], 10);
                        const columnEnd = excelColumnToNumber(mergecellEnd[1]);
                        const rowEnd = parseInt(mergecellEnd[2], 10);
    
                        worksheet.mergeCells(rowStart, columnStart, rowEnd, columnEnd);
                        const headerCell = worksheet.getCell(rowStart, columnStart);
                        // headerCell.value = `${sm_table_align == 0 ? title?.props?.children[0] : title?.props?.children} `;
                        headerCell.value = `${sm_table_align == 0 ? title : ''}`;
                        headerCell.style = {
                            font: { bold: true, size: 15,color: { argb: "510f30" } },
                            alignment: { vertical: "middle", horizontal: "left" },
                        };
                    }
                    workbook.xlsx.writeBuffer().then((buffer) => {
                        const blob = new Blob([buffer], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${sm_table_align == 0 ? title : ''}.xlsx`;
                        link.click();
                    });
                };
                reader.readAsDataURL(blob);
            }catch(error){
                window.alert(error.message)
            }
            
        });
};
function excelColumnToNumber(column) {
    let result = 0;
    for (let i = 0; i < column.length; i++) {
        result = result * 26 + column.charCodeAt(i) - "A".charCodeAt(0) + 1;
    }
    return result;
}

export default downloadExcel;