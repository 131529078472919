import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import "./ProfileView.css";
import close from "../../Images/close.svg"
import closered from "../../Images/close.png"
import No_image_available from "../../Images/No_image_available.svg"
import CloseIcon from "@material-ui/icons/Close";
import MaleImg from "../../assests/images/MaleJpg.jpg"
import FemaleImg from "../../assests/images/FeMaleJpg.jpg"

const styles = {};
var moment = require('moment');

export default class ProfileView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cancel: null };
  }


  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };
  open = () => {
    this.setState({ view: true })
  }
  onclose = () => {
    this.setState({ view: false })
  }

  formatTimeShow = (h_24) => {
    var h = Number(h_24.substring(0, 2)) % 12;
    if (h === 0) h = 12;
    return (h < 10 ? '0' : '') + h + ':' + h_24.substring(3, 5) + (Number(h_24.substring(0, 2)) < 12 ? ' AM' : ' PM');
  }

  render() {
    const viewdata = this.props.viewdata
    console.log(viewdata, "props")
    const styles = "";
    const { classes, onClose, cancel, selectedValue, ...other } = this.props;
    const Dutiesofnurse = viewdata.Dutiesofnurse ? JSON.parse(viewdata.Dutiesofnurse) : [];
    return (
      <div className="doctor_popup_details">

        <Dialog
          aria-labelledby="simple-dialog-title"
          {...other}
          className="nurse_profile_modal"
        >
          <div>
            {/* <img className="close_ico"  src={close}  onClick={this.props.onClose}/> */}
            {/* <img src={viewdata.profile_image} className="patient"/> */}
            <CloseIcon className="modalcomp_closeicon" onClick={onClose} />
            {/* <img className="close_ico"  src={closered}  onClick={this.props.onClose}/> */}
            <img
              src={
                this.props.isnursehistory
                  ? viewdata.patientImage
                    ? viewdata.patientImage
                    : viewdata.patientgender === 'Male'
                      ? MaleImg
                      : FemaleImg
                  : viewdata.nurseimage
                    ? viewdata.nurseimage
                    : viewdata.patientgender === 'Male'
                      ? MaleImg
                      : FemaleImg
              }
              alt="This IMG format is not supported"
              style={{ height: "10px" }} // Corrected to include units
              className="patient"
            />

          </div>
          <Grid>
            <div className="doctor_dashboard_view">
              <div className="doctor_details_container">
                <div className="doctor_detailsdiv">
                  <h3 className="patient_name">{(this.props.isnursehistory ? viewdata.PatientName : viewdata.Nursename)}</h3>
                  <p className="patient_age">{(this.props.isnursehistory ? viewdata.patientage : viewdata.nurseage)} yrs , {(this.props.isnursehistory ? viewdata.patientgender : viewdata.nursegender)}</p>
                  <p className="booked_details_font">Ordered Details</p>
                  <Grid className="d-flex mt-5">
                    <Grid item md={6} sm={6}>
                      <div className="profile_detail_main_div">
                        <div className="profile_detail_lable_div"> Date </div>
                        <div className="profile_detail_colon_div"> : </div>
                        <div className="profile_detail_div"> {moment(viewdata.book_date).format('DD-MMM-YY')} </div>
                      </div>

                      <div className="profile_detail_main_div">
                        <div className="profile_detail_lable_div"> Time </div>
                        <div className="profile_detail_colon_div"> : </div>
                        <div className="profile_detail_div"> {viewdata && viewdata.book_date ? moment(viewdata.book_date).format("h:mm a") : "--"} </div>
                      </div>
                    </Grid>
                    <Grid item md={6} sm={6} className="date_div">
                      <div className="profile_detail_main_div">
                        <div className="profile_detail_lable_div"> From Date </div>
                        <div className="profile_detail_colon_div"> : </div>
                        <div className="profile_detail_div"> {moment(viewdata.from_date).format('DD-MMM-YY')} </div>
                      </div>

                      <div className="profile_detail_main_div">
                        <div className="profile_detail_lable_div"> To Date </div>
                        <div className="profile_detail_colon_div"> : </div>
                        <div className="profile_detail_div"> {moment(viewdata.to_date).format('DD-MMM-YY')} </div>
                      </div>
                    </Grid>
                  </Grid>
                  <div className="divider_root" />
                  <Grid className="d-flex mt-3">
                    <Grid item md={4} sm={4}>
                      <div className="profile_patientappointment_details" style={{ color: 'black !important' }}> Defined Duties </div>
                    </Grid>
                    <Grid item md={8} sm={8}>
                      <div className="duties_main_div">
                        {
                          Dutiesofnurse.map((obj) => (
                            <div className="duties_body_div"> {obj.duties}</div>
                          ))
                        }
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </Grid>
        </Dialog>

      </div>
    );
  }
}
const Trainer_viewWrapped = withStyles(styles)(ProfileView);
