import React from "react";
import "antd/dist/antd.css";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";
import Button from "@material-ui/core/Button";
import "./BasicDetails.css";
import "../../helpers/labelbox/labelbox";
import ValidationLibrary from "../../helpers/validationfunction";
import Axios from "axios";
import { apiurl } from "../../App";
import RightArrowOnly from "../../Images/right-arrow-only.png";
import LeftArrowOnly from "../../Images/left-arrow-only.png";
import BankDetails from "./BankDetails";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import BankIcon from "../../Images/bank.svg";
import ShowNotification from "../ShowNotification/ShowNotification";
import PropTypes from "prop-types";
class BasicDetails extends React.Component {
  state = {
    open: "",
    Address: "",
    editId: "",
    bankDetailsData: false,
    ProfileEditData: {
      Address: {
        value: "",
        validation: [{ name: "required" }, { name: "address" }],
        error: null,
        errmsg: null,
      },
      Contact: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      Website: {
        value: "",
        validation: [{ name: "webUrl" }],
        error: null,
        errmsg: null,
      },
      Mobile: {
        value: "",
        validation: [{ name: "required" }, { name: "mobileHms" }],
        error: null,
        errmsg: null,
      },
      Email: {
        value: "",
        validation: [{ name: "required" }, { name: "email" }],
        error: null,
        errmsg: null,
      },
      Since: {
        value: "",
        validation: [{ name: "required" }, { name: "since" }],
        error: null,
        errmsg: null,
      },
      VendorPhone: {
        value: "",
        validation: [{ name: "required" }, { name: "mobileHms" }],
        error: null,
        errmsg: null,
      },
      VendorEmail: {
        value: "",
        validation: [{ name: "required" }, { name: "email" }],
        error: null,
        errmsg: null,
      },
      VendorName: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
    },
    openBankDetails: false,
    visible: false,
    successmsg: "",
    issuccess: false,
    isLoading:false,
    // edit_vendor_address: null,
    // edit_vendor_contact: null,
    // edit_vendor_website: null,
    // edit_vendor_contact_mobile: null,
    // edit_vendor_contact_email: null,
    // edit_vendor_since: null,
    // edit_vendor_phone: null,
    // edit_vendor_name: null,
    // edit_vendor_email: null,
    // account_name: null,
    // bank_name: null,
    // branch_area: null,
    // iban: null
  };

  bankDetails_errCheck() {
    if ((this.state.account_name == null &&
      this.state.bank_name == null && this.state.branch_area == null &&
      this.state.iban == null) || ((this.state.account_name == "" &&
        this.state.bank_name == "" && this.state.branch_area == "" &&
        this.state.iban == ""))) {
      this.setState({ bankDetailsData: true });
    }
  }
  handleClose = () => {
    this.props.closemodal();
  };

  componentDidMount() {
    const { EditData, EditOpen } = this.props;
    this.props.ProfileGetApi();
    if (EditOpen === true) {
      this.state.ProfileEditData.Address.value = EditData[0].vendor_address;
      this.state.ProfileEditData.Contact.value = EditData[0].vendor_contact;
      this.state.ProfileEditData.Website.value = EditData[0].vendor_website;
      this.state.ProfileEditData.Mobile.value =
        EditData[0].vendor_contact_mobile;
      this.state.ProfileEditData.Email.value = EditData[0].vendor_contact_email;

      this.state.ProfileEditData.Since.value = EditData[0].vendor_since;
      this.state.ProfileEditData.VendorPhone.value = EditData[0].vendor_phone;
      this.state.ProfileEditData.VendorName.value = EditData[0].vendor_name;
      this.state.ProfileEditData.VendorEmail.value = EditData[0].vendor_email;

      this.state.account_name = EditData[0].account_name;
      this.state.bank_name = EditData[0].bank_name;
      this.state.branch_area = EditData[0].branch_area;
      this.state.iban = EditData[0].iban;

      this.state.edit_vendor_address = EditData[0].vendor_address;
      this.state.edit_vendor_contact = EditData[0].vendor_contact;
      this.state.edit_vendor_website = EditData[0].vendor_website;
      this.state.edit_vendor_contact_mobile = EditData[0].vendor_contact_mobile;
      this.state.edit_vendor_contact_email = EditData[0].vendor_contact_email;

      this.state.edit_vendor_since = EditData[0].vendor_since;
      this.state.edit_vendor_phone = EditData[0].vendor_phone;
      this.state.edit_vendor_name = EditData[0].vendor_name;
      this.state.edit_vendor_email = EditData[0].vendor_email;

    }
    this.setState({});
  }

  changeDynamic = (data, key) => {
    let ProfileEdit = this.state.ProfileEditData;
    let errorcheck = ValidationLibrary.checkValidation(
      data,
      ProfileEdit[key].validation
    );
    ProfileEdit[key].value = data;
    ProfileEdit[key].error = !errorcheck.state;
    ProfileEdit[key].errmsg = errorcheck.msg;
    this.setState({ ProfileEdit });
    this.setState({});
  };

  checkValidation = () => {
    let Profile = { ...this.state.ProfileEditData }; 
    delete Profile.Website;
    let ProfileKeys = Object.keys(Profile);
    for (let i in ProfileKeys) {
      let errorcheck = ValidationLibrary.checkValidation(
        Profile[ProfileKeys[i]].value,
        Profile[ProfileKeys[i]].validation
      );
      Profile[ProfileKeys[i]].error = !errorcheck.state;
      Profile[ProfileKeys[i]].errmsg = errorcheck.msg;
    }
    let filtererr = ProfileKeys.filter((obj) => Profile[obj].error == true);
    if (filtererr.length > 1) {
      this.setState({ error: true });
    }
    else if ((this.state.account_name == null &&
      this.state.bank_name == null && this.state.branch_area == null &&
      this.state.iban == null) || (this.state.account_name == "" &&
        this.state.bank_name == "" && this.state.branch_area == "" &&
        this.state.iban == "")) {
      {
        this.setState({ bankDetailsData: true });
      }
    } else {
      this.setState({ error: false });
      this.EditProfileApi();
      this.setState({ Profile });
    }
  };

  ProfileGetApibank = () => {
    Axios({
      method: "POST",
      url: apiurl + "Nurse/getnursevendorprofile",
      data: {
        nursevendorId: this.props.EditData[0].VendorId,
      },
    })
      .then((response) => {
        this.state.account_name = response.data.data[0].account_name;
        this.state.bank_name = response.data.data[0].bank_name;
        this.state.branch_area = response.data.data[0].branch_area;
        this.state.iban = response.data.data[0].iban;
        this.state.bankDetailsData = false;
      })
      .catch((error) => { });
  };

  
  EditProfileApi = () => {
    let formData = new FormData();
    formData.set("address", this.state.ProfileEditData.Address.value);
    formData.set("mobile", this.state.ProfileEditData.Mobile.value);
    formData.set("email", this.state.ProfileEditData.Email.value);
    formData.set("website", this.state.ProfileEditData.Website.value );
    formData.set("contact", this.state.ProfileEditData.Contact.value);
    formData.set("modifiedby", 1);
    formData.set("nursevendorId", this.props.EditId);
    formData.append("uploadFile", this.props.Image);
    formData.set("vendorsince", this.state.ProfileEditData.Since.value);

    Axios({
      method: "POST",
      url: apiurl + "Nurse/editNursevendorprofile",
      data: formData,
    })
      .then((response) => {
        this.props.closemodal();
        this.props.ProfileGetApi();
        if (localStorage.nurse_language == 'ar') {
          this.props.handleopenSuccess(".تم تحديث الملف الشخصي", true);
        } else {
          this.props.handleopenSuccess("Profile updated successfully!", true);
        }
      })
      .catch((error) => { });
  };

  handleBankDetailsPopup = (isOpen) => {
    this.setState({ openBankDetails: isOpen });
  };

  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.props.ProfileGetApi();
    this.setState({ visible: false });
  };

  formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.toLocaleDateString("en-US", { day: "2-digit" });
    const month = date.toLocaleDateString("en-US", { month: "short" });
    const year = date.toLocaleDateString("en-US", { year: "2-digit" });
    return `${day}-${month}-${year}`;
  };

  render() {
    const { t } = this.props;
    return (
      <div style={{ direction: this.props.i18n.language == "ar" ? "rtl" : "" }} className={`${this.props.i18n.language == "ar" ? "arabic_cls" : ""} basic_detail_div`}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >

            <div className="basic_address_details">
              <Labelbox
                type="text"
                labelname={t("address")}
                changeData={(data) => this.changeDynamic(data, "Address")}
                value={this.state.ProfileEditData.Address.value}
                error={this.state.ProfileEditData.Address.error}
                errmsg={this.state.ProfileEditData.Address.errmsg}
              />

              <Labelbox
                type="text"
                labelname={t("contact_person_name")}
                changeData={(data) => {
                  // Filter out non-alphabetic characters
                  const filteredData = data.replace(/[^a-zA-Z\s]/g, "");
                  this.changeDynamic(filteredData, "Contact");
                }}
                value={this.state.ProfileEditData.Contact.value}
                error={this.state.ProfileEditData.Contact.error}
                errmsg={this.state.ProfileEditData.Contact.errmsg}
              />


              {this.state.edit_vendor_phone == null ? (
                <Labelbox
                  type="text"
                  labelname={t("contact_person_number")}
                  changeData={(data) => this.changeDynamic(data, "VendorPhone")}
                  value={this.state.ProfileEditData.Mobile.value}
                  error={this.state.ProfileEditData.Mobile.error}
                  errmsg={this.state.ProfileEditData.Mobile.errmsg}
                />
              ) : (
                <Labelbox
                  type="text"
                  labelname={t("contact_person_number")}
                  value={this.state.ProfileEditData.Mobile.value}
                  error={this.state.ProfileEditData.Mobile.error}
                  errmsg={this.state.ProfileEditData.Mobile.errmsg}
                />
              )}

              
               
                <Labelbox
                  type="text"
                  labelname={t("website")}
                  placeholder=""
                  valuelabel={"website"}
                  changeData={(data) => this.changeDynamic(data, "Website")}
                  value={this.state.ProfileEditData.Website.value != "null" ? this.state.ProfileEditData.Website.value : ""}
                  error={this.state.ProfileEditData.Website.error}
                  errmsg={this.state.ProfileEditData.Website.errmsg}
                />
           

              <div className="contact_align_lab">
                <div className="formdiv" >
                  <label className="labeltxt"
                    style={{
                      display: "flex",
                      justifyContent:
                        this.props.i18n.language == "ar"
                          ? "right"
                          : "space-between",
                    }}
                  >
                    {t("bank_details")}
                  </label>
                  <div className="bank-name-details"
                    onClick={() => this.handleBankDetailsPopup(true)}
                  >
                    <span>
                      {(this.props.EditData &&
                        this.props.EditData[0]?.bank_name) ||
                        "Bank Details"}
                    </span>
                    {localStorage.nurse_language == 'ar' ?
                      <img
                        src={LeftArrowOnly}
                        width={8}
                        height={12}
                        alt="arrow"
                        style={{ cursor: "pointer" }}
                      /> :
                      <img
                        src={RightArrowOnly}
                        width={12}
                        height={12}
                        alt="arrow"
                        style={{ cursor: "pointer" }}
                      />
                    }
                  </div>
                </div>
                {this.state.bankDetailsData == true ? (
                  <div className="Errormsg" style={{ textAlign: localStorage.nurse_language == "ar" ? "right" : "" }}>
                    <span>
                      {localStorage.nurse_language === 'ar' ? "الحقل مطلوب" : "Field required"}
                    </span>
                  </div>
                   ) : ""}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <div className="basic_address_details">
              {this.state.edit_vendor_contact_email == "" ? (
                <Labelbox
                  type="text"
                  labelname={t("contact_person_email")}
                  changeData={(data) => this.changeDynamic(data, "Email")}
                  value={this.state.ProfileEditData.Email.value}
                  error={this.state.ProfileEditData.Email.error}
                  errmsg={this.state.ProfileEditData.Email.errmsg}
                />
              ) : (
                <Labelbox
                  type="text"
                  labelname={t("contact_person_email")}
                  value={this.state.ProfileEditData.Email.value}
                  error={this.state.ProfileEditData.Email.error}
                  errmsg={this.state.ProfileEditData.Email.errmsg}
                />
              )}

              {this.state.edit_vendor_name == "" ? (
                <Labelbox
                  type="text"
                  labelname={t("company_name")}
                  changeData={(data) => this.changeDynamic(data, "VendorName")}
                  value={this.state.ProfileEditData.VendorName.value}
                  error={this.state.ProfileEditData.VendorName.error}
                  errmsg={this.state.ProfileEditData.VendorName.errmsg}
                />
              ) : (
                <Labelbox
                  type="text"
                  labelname={t("company_name")}
                  value={this.state.ProfileEditData.VendorName.value}
                  error={this.state.ProfileEditData.VendorName.error}
                  errmsg={this.state.ProfileEditData.VendorName.errmsg}
                />
              )}

              {this.state.edit_vendor_contact_mobile == "" ? (
                <Labelbox
                  type="number"
                  labelname={t("company_contact_number")}
                  changeData={(data) => this.changeDynamic(data, "Mobile")}
                  value={this.state.ProfileEditData.VendorPhone.value}
                  error={this.state.ProfileEditData.VendorPhone.error}
                  errmsg={this.state.ProfileEditData.VendorPhone.errmsg}
                />
              ) : (
                <Labelbox
                  type="number"
                  labelname={t("company_contact_number")}
                  value={this.state.ProfileEditData.VendorPhone.value}
                  error={this.state.ProfileEditData.VendorPhone.error}
                  errmsg={this.state.ProfileEditData.VendorPhone.errmsg}
                />
              )}

              {this.state.edit_vendor_email == "" ? (
                <Labelbox
                  type="text"
                  labelname={t("company_email")}
                  changeData={(data) =>
                    this.changeDynamic(data, " VendorEmail ")
                  }
                  value={this.state.ProfileEditData.VendorEmail.value}
                  error={this.state.ProfileEditData.VendorEmail.error}
                  errmsg={this.state.ProfileEditData.VendorEmail.errmsg}
                />
              ) : (
                <Labelbox
                  type="text"
                  labelname={t("company_email")}
                  value={this.state.ProfileEditData.VendorEmail.value}
                  error={this.state.ProfileEditData.VendorEmail.error}
                  errmsg={this.state.ProfileEditData.VendorEmail.errmsg}
                />
              )}
            </div>
          </Grid>

          <Grid item xs={12} md={12} className="profile_cont">
            <div style={{ marginRight: '40px' }}>
              <Button className="profile_Cancel"
                onClick={() => this.props.closemodal(false)}
              >
                {t("cancel")}
              </Button>

              <Button className="profile_Submit"
                style={{ margin: this.props.i18n.language == "ar" ? "0px 20px 0px 40px" : "" }}
                onClick={() => { this.checkValidation() }}
              >
                {t("update")}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>

        {this.props.EditData && (
          <Modalcomp
            clrchange={`${this.props.i18n.language == "ar" ? "arabic-direct" : ""} text_color `}
            visible={this.state.openBankDetails}
            modelwidthClass={`${this.props.i18n.language == "ar" ? "arabic_cls" : ""
              } `}
            title={
              <>
                <img
                  src={BankIcon}
                  width={30}
                  height={30}
                  alt="Bank"
                  className={`${localStorage.nurse_language == "ar" ? "ar_bank_icon" : ""} bank-details-title `}
                />
                &nbsp;&nbsp; {t("bank_detail_head")}
              </>
            }
            closemodal={(e) => this.setState({ openBankDetails: false })}
            t={t}
            i18n={this.props.i18n}
          >
            <BankDetails
              openPopup={this.state.openBankDetails}
              BankDetails={this.props.EditData[0]}
              handleopenSuccess={this.handleopenSuccess}
              ProfileGetApi={() => this.props.ProfileGetApi()}
              onClose={() => {
                this.setState({ openBankDetails: false });
              }}
              t={t}
              bankofdetails={() => this.ProfileGetApibank()}
              i18n={this.props.i18n}
            />
          </Modalcomp>
        )}
        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={this.handleCloseSuccess}
          t={t}
          i18n={this.props.i18n}
        ></ShowNotification>
      </div>
    );
  }
};

BasicDetails.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  closemodal: PropTypes.func.isRequired,
  editData: PropTypes.object,
  editData: PropTypes.shape({
    id: PropTypes.string,
    vendor_address: PropTypes.string,
    vendor_phone: PropTypes.string,
    vendor_contact: PropTypes.number,
    iban: PropTypes.number,
    vendor_since: PropTypes.number,
    vendor_contact_email: PropTypes.string,
    vendor_website: PropTypes.string,
    vendor_name: PropTypes.string,
    vendor_email: PropTypes.string,
    branch_area: PropTypes.string,
    bank_name: PropTypes.string,
    account_number: PropTypes.string,
    account_name: PropTypes.string,
  }),
};
BasicDetails.defaultProps = {
  editData: null,
};
export default BasicDetails;
